import React from "react";
import {ConfirmModal} from "../utils/Modal";
import Button from "@mui/material/Button";

export default class ActionChoice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actions: props.actions[props.userId][0],
            show: false
        };
    }

    componentDidMount() {
    }


    render() {
        let modal = <ConfirmModal
            action={this.state.show ? this.state.action : undefined}
            text={"Confirmez-vous votre choix ?"}
            socket={this.props.socket}
            onDismiss={() => this.setState({show: false})}
            open={this.state.show}
        />;
        return <div
            style={{
                margin: "0", overflow: "hidden", width: "100%", height: "100%", color: "#DDD",
                display: "flex", flexDirection: "column"
            }}>
            {modal}
            <div style={{flex: 1, fontSize: "20px", margin: '3vh 0', alignItems: "center", display: "flex"}}>
                Que souhaitez-vous faire ?
            </div>
            <div style={{flex: 5, marginLeft: "5%", width: "95%"}}>
                {
                    this.props.actions[this.props.userId].map((action, i) =>
                        <div key={action.name} style={{}}>
                            <div style={{
                                width: '100%', backgroundColor: "#1f2136", borderRadius: "10px", display: "flex",
                                flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
                            }}>
                                <div style={{flex: 1, fontSize: "18px"}}>
                                    {
                                        action.class === "model.actions.others.ActionAddResource" ?
                                            <span style={{}}>
                                            {action.nbResources > 0 ? "+" : ""}{action.nbResources + "  "}
                                                <img
                                                    src={"/public/terraforming/icons/resource_" + action.resources.toLowerCase() + ".png"}
                                                    style={{height: "40px", width: "auto"}}
                                                    alt={action.resources}/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.main.ActionCardAction" ?
                                            <span style={{}}>
                                            {action.cardActionNames}
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionAddProd" ?
                                            <span style={{}}>
                                            {action.nbResources > 0 ? "+" : ""}{action.nbResources + "  "}
                                                <img
                                                    src={"/public/terraforming/icons/prod_" + action.resources.toLowerCase() + ".png"}
                                                    style={{height: "40px", width: "auto"}}
                                                    alt={action.resources}/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionPayPurificationTitan" ||
                                        action.class === "model.actions.others.ActionPayTitaniumToAddCardResources" ?
                                            <span style={{}}>
                                            -&nbsp;
                                                <img src={"/public/terraforming/icons/resource_titanium.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="titanium"/>
                                                &nbsp;&nbsp;&nbsp;+2&nbsp;
                                                <img src={"/public/terraforming/icons/resource_flotteur.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="flotteur"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionPurificationTitan" ?
                                            <span style={{}}>
                                            -2&nbsp;
                                                <img src={"/public/terraforming/icons/resource_flotteur.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="flotteur"/>
                                                &nbsp;&nbsp;&nbsp;+&nbsp;
                                                <img src={"/public/terraforming/icons/nt-image.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="titanium"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionBallonsExtracteurs" ||
                                        action.class === "model.actions.others.ActionPrecipitationInduites" ||
                                        action.class === "model.actions.others.ActionMicroprojetPurification" ?
                                            <span style={{}}>
                                            -2&nbsp;
                                                <img src={"/public/terraforming/icons/resource_flotteur.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="flotteur"/>
                                                &nbsp;&nbsp;&nbsp;+&nbsp;
                                                <img src={"/public/terraforming/icons/venus.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="titanium"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionThermophiles" ?
                                            <span style={{}}>
                                            -2&nbsp;
                                                <img src={"/public/terraforming/icons/resource_microbes.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="flotteur"/>
                                                &nbsp;&nbsp;&nbsp;+&nbsp;
                                                <img src={"/public/terraforming/icons/venus.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="titanium"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionExportationDeuterium" ?
                                            <span style={{}}>
                                            -1&nbsp;
                                                <img src={"/public/terraforming/icons/resource_flotteur.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="flotteur"/>
                                                &nbsp;&nbsp;&nbsp;+&nbsp;
                                                <img src={"/public/terraforming/icons/prod_energy.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="titanium"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionOmbrageSelectif" ?
                                            <span style={{}}>
                                            -1&nbsp;
                                                <img src={"/public/terraforming/icons/resource_flotteur.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="flotteur"/>
                                                &nbsp;&nbsp;&nbsp;+&nbsp;
                                                <img src={"/public/terraforming/icons/prod_megacredit.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="titanium"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionImpactRotatif" ?
                                            <span style={{}}>
                                            -&nbsp;
                                                <img src={"/public/terraforming/icons/resource_asteroide.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="flotteur"/>
                                                &nbsp;&nbsp;&nbsp;+&nbsp;
                                                <img src={"/public/terraforming/icons/venus.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="titanium"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionPayToAddFlotteur" ?
                                            <span style={{}}>
                                            {
                                                action.cost ?
                                                    <span>
                                                        -{action.cost}&nbsp;
                                                        <img src={"/public/terraforming/icons/resource_megacredit.png"}
                                                             style={{height: "40px", width: "auto"}}
                                                             alt="flotteur"/>
                                                        &nbsp;&nbsp;&nbsp;
                                                    </span> :
                                                    undefined
                                            }
                                                {
                                                    action.costTitanium ?
                                                        <span>-{action.costTitanium}&nbsp;
                                                            <img
                                                                src={"/public/terraforming/icons/resource_titanium.png"}
                                                                style={{height: "40px", width: "auto"}}
                                                                alt="flotteur"/>
                                                            &nbsp;&nbsp;&nbsp;
                                                </span> :
                                                        undefined
                                                }
                                                +&nbsp;
                                                <img
                                                    src={"/public/terraforming/icons/resource_" + action.cardResources + ".png"}
                                                    style={{height: "40px", width: "auto"}}
                                                    alt="titanium"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionObservatoireTacheRouge" ||
                                        action.class === "model.actions.others.ActionCartographe" ?
                                            <span style={{}}>
                                           -1&nbsp;
                                                <img src={"/public/terraforming/icons/resource_flotteur.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt={action.resources}/>
                                                &nbsp;&nbsp;&nbsp;+1&nbsp;
                                                <img src={"/public/terraforming/icons/bonus_card.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt={action.resources}/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionSetStateAddResourcesToCard" ||
                                        action.class === "model.actions.others.ActionAddResourceToCard" ?
                                            <span style={{}}>
                                            {action.nbResources > 0 ? "+" : ""}{action.nbResources + "  "}
                                                <img
                                                    src={"/public/terraforming/icons/resource_" + action.cardResources.toLowerCase() + ".png"}
                                                    style={{height: "40px", width: "auto"}}
                                                    alt={action.cardResources}/>&nbsp;
                                                {action.cardName ? action.cardName : ""}
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionStationFlottanteJovienneMegacredit" ?
                                            <span style={{}}>
                                            {action.value >= 0 ? "+" : ""}{action.value + "  "}
                                                <img src={"/public/terraforming/icons/resource_megacredit.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="megacredit"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionAddResourceToCardWithBadge" ?
                                            <span style={{}}>
                                            {action.nbFlotteurs > 0 ? "+" : ""}{action.nbFlotteurs + "  "}
                                                <img
                                                    src={"/public/terraforming/icons/resource_" + action.cardResources + ".png"}
                                                    style={{height: "40px", width: "auto"}}
                                                    alt="flotteur"/>
                                                &nbsp;
                                                {
                                                    action.badges ?
                                                        "(" + action.badges + ")" :
                                                        ""
                                                }
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionRegolytophaghes" ?
                                            <span style={{}}>
                                            -2&nbsp;
                                                <img src={"/public/terraforming/icons/resource_microbes.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt={action.cardResources}/>
                                                &nbsp;&nbsp;
                                                <img src={"/public/terraforming/icons/oxygen.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="temperature"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionRaiseTemperature" ?
                                            <span style={{}}>
                                            {action.number}
                                                &nbsp;
                                                <img src={"/public/terraforming/icons/temperature.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="temperature"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionRaiseVenus" ?
                                            <span style={{}}>
                                            {action.number}
                                                &nbsp;
                                                <img src={"/public/terraforming/icons/venus.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="temperature"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionCollecteurAtmospherique" ?
                                            <span style={{}}>
                                            -1&nbsp;
                                                <img src={"/public/terraforming/icons/resource_flotteur.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt={action.cardResources}/>
                                                &nbsp;&nbsp;{action.value}&nbsp;
                                                <img
                                                    src={"/public/terraforming/icons/resource_" + action.resources + ".png"}
                                                    style={{height: "40px", width: "auto"}}
                                                    alt={action.resources}/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.main.ActionRecompense" ?
                                            <span style={{}}>
                                            <img src={"/public/terraforming/icons/recompense_" +
                                            action.recompenses.toLowerCase() + ".png"}
                                                 style={{height: "40px", width: "auto"}}
                                                 alt={action.recompenses}/>
                                                &nbsp;&nbsp;{action.recompenses}&nbsp;
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionStealAny" ?
                                            <span style={{}}>
                                            -{action.nbResources}&nbsp;
                                                <img
                                                    src={"/public/terraforming/icons/resource_" + action.resources + ".png"}
                                                    style={{height: "40px", width: "auto"}}
                                                    alt={action.resources}/>
                                                &nbsp;&nbsp;{action.victimName}
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionBacteriesGES" ?
                                            <span style={{}}>
                                            -2&nbsp;
                                                <img src={"/public/terraforming/icons/resource_microbes.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt={action.cardResources}/>
                                                &nbsp;&nbsp;
                                                <img src={"/public/terraforming/icons/temperature.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="temperature"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionBacteriesDenitrifiantes" ?
                                            <span style={{}}>
                                            -3&nbsp;
                                                <img src={"/public/terraforming/icons/resource_microbes.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt={action.cardResources}/>
                                                &nbsp;&nbsp;
                                                <img src={"/public/terraforming/icons/nt-image.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="temperature"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionCatapulteElectromagnetique" ?
                                            <span style={{}}>
                                            -1&nbsp;
                                                <img
                                                    src={"/public/terraforming/icons/resource_" + action.resources.toLowerCase() + ".png"}
                                                    style={{height: "40px", width: "auto"}}
                                                    alt={action.resources}/>
                                                &nbsp;&nbsp;+7&nbsp;
                                                <img src={"/public/terraforming/icons/megacredit.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt="money"/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionChoiceRemoveAnimal" ?
                                            <span style={{}}>
                                            -2&nbsp;
                                                <img src={"/public/terraforming/icons/resource_animal.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt={action.resources}/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionChoiceRemovePlant" ?
                                            <span style={{}}>
                                            -5&nbsp;
                                                <img src={"/public/terraforming/icons/resource_plant.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt={action.resources}/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionRobinson" ?
                                            <span style={{}}>
                                            - 4&nbsp;
                                                <img src={"/public/terraforming/icons/resource_megacredit.png"}
                                                     style={{height: "40px", width: "auto"}}
                                                     alt={action.resources}/>
                                                &nbsp;&nbsp;
                                                +&nbsp;
                                                <img
                                                    src={"/public/terraforming/icons/prod_" + action.resource.toLowerCase() + ".png"}
                                                    style={{height: "40px", width: "auto"}}
                                                    alt={action.resources}/>
                                        </span>
                                            : undefined
                                    }
                                    {
                                        action.class === "model.actions.others.ActionUtopia" ?
                                            <span style={{}}>
                                            -&nbsp;
                                                <img
                                                    src={"/public/terraforming/icons/prod_" + action.resource.toLowerCase() + ".png"}
                                                    style={{height: "40px", width: "auto"}}
                                                    alt={action.resources}/>
                                                &nbsp;&nbsp;
                                                +&nbsp;
                                                <img
                                                    src={"/public/terraforming/icons/resource_" + action.resource.toLowerCase() + ".png"}
                                                    style={{height: "40px", width: "auto"}}
                                                    alt={action.resources}/>
                                        </span>
                                            : undefined
                                    }
                                </div>
                                <div>
                                    <Button variant={"contained"}
                                            disabled={!action.ok}
                                            onClick={() => this.setState({action, show: true})}>
                                        Choisir
                                    </Button>
                                </div>
                            </div>
                            {
                                i < this.props.actions[this.props.userId].length - 1 ?
                                    <div style={{width: '100%', margin: '2vh 0', textAlign: 'center'}}>
                                        OU
                                    </div> :
                                    undefined
                            }
                        </div>
                    )
                }
            </div>
        </div>
    }
}
