import {selectSessionUser} from "../../../../../store/authentication/selectors";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {useSelector} from "react-redux";
import constants from "../../../../../config/constants";
import * as io from "socket.io-client";
import "../BoardGame.css";
import DraftComponent from "./DraftComponent";
import EndDraftComponent from "./EndDraftComponent";
import MainTurnComponent from "./MainTurnComponent";
import ActionPlaceTile from "./others/ActionPlaceTile";
import ActionCostReduction from "./others/ActionCostReduction";
import CorpoChoiceComponent from "./CorpoChoiceComponent";
import ActionAddResources from "./others/ActionAddResources";
import ActionChoice from "./others/ActionChoice";
import ActionConsultDeck from "./others/ActionConsultDeck";
import ActionInfrastructureElectrique from "./others/ActionInfrastructureElectrique";
import ActionMainDoeuvreRobotique from "./others/ActionMainDoeuvreRobotique";
import ActionSelectFromDeck from "./others/ActionSelectFromDeck";
import ActionIsolation from "./others/ActionIsolation";
import ActionRevendicationTerrain from "./others/ActionRevendicationTerrain";
import ActionRemoveAny from "./others/ActionRemoveAny";
import StateIntro from "./mainScreens/StateIntro";
import PreludeComponent from "./PreludeComponent";
import ActionValleyTrust from "./others/ActionValleyTrust";
import ActionNavetteTitan from "./others/ActionNavetteTitan";
import ActionDiscardCard from "./others/ActionDiscardCard";
import ActionBacteriesSulfoOxydantes from "./others/ActionBacteriesSulfoOxydantes";
import Box from "@mui/material/Box";
import ConsultScreen from "./consult/ConsultScreen";


export const TerraformingHome = () => {

    const history = useHistory();
    const location = useLocation();

    const [state, setState] = useState({
        state: undefined,
        play: true,
        consult: "hands",
        checkNewState: false
    });

    const user = useSelector(selectSessionUser);

    let [socket, setSocket] = useState(undefined);

    const gameId = location.pathname.split("/")[2];

    useEffect(() => {
        const tempSocket = io.connect(constants.api.socketPath);
        setTimeout(() => {
            tempSocket.emit("id", {
                type: "PLAYER",
                gameId,
                userId: "" + user._id,
                username: user.username,
                color: {r: 1, g: 1, b: 1, a: 1}
            })
        }, 1000);
        tempSocket.on('disconnect', function () {
            history.push("/board-games");
        });
        tempSocket.on('state', function (data) {
            console.log('new state');
            console.log(data);
            if (data && (data.state !== "INTRO" || !state.state)) {
                setState(state => {
                    const newState = {state: data};
                    if (state.play && (!data.actions || !data.actions[user._id] || !data.actions[user._id].length)) {
                        newState.play = false;
                        newState.checkNewState = false;
                    } else if (!state.play && data.actions && data.actions[user._id] && data.actions[user._id].length && !state.checkNewState) {
                        newState.play = true;
                        newState.checkNewState = true;
                    }
                    return {...state, ...newState};
                });
            }
        });
        tempSocket.on('state_intro', function (data) {
            console.log('new state intro');
            console.log(data);
            setState(state => {
                if (!state.state || state.state.state === "INTRO") {
                    return {...state, state: data};
                }
                return state;
            });
        });
        setSocket(tempSocket);
        return () => {
            tempSocket.close();
        };
    }, []);


    function getCardActions() {
        if (!state.state || !state.state.actions || !state.state.actions[props.user._id]) {
            return [];
        }
        return state.state.actions[props.user._id].filter(action => action.class === "model.actions.main.ActionCardAction");
    }

    let comp = undefined;
    if (!state.state) {
        return <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
            paddingTop: "15vh",
            marginBottom: "90vh"
        }}>
            Veuillez patienter
        </div>
    }
    if (state.state) {
        switch (state.state.state) {
            case "DRAFT":
                comp = <DraftComponent
                    state={state.state}
                    userId={user._id}
                    draftCard={(cardId) => socket.emit("action", {
                        draftedCardId: cardId,
                        userId: user._id
                    })}
                />;
                break;
            case "END_DRAFT":
                comp = <EndDraftComponent
                    state={state.state}
                    userId={user._id}
                    socket={socket}
                />;
                break;
            case "PRELUDE":
                comp = <PreludeComponent
                    state={state.state}
                    userId={user._id}
                    socket={socket}
                />;
                break;
            case "CORPO_CHOICE":
                comp = <CorpoChoiceComponent
                    state={state.state}
                    userId={user._id}
                    socket={socket}
                />
                break;
            case "MAIN":
                comp = <MainTurnComponent
                    state={state.state}
                    userId={user._id}
                    socket={socket}/>
                break;
            case "ACTION_PLACE_TILE":
                comp = <ActionPlaceTile
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}/>
                break;
            case "COST_REDUCTION":
                comp = <ActionCostReduction
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />;
                break;
            case "VALLEY_TRUST":
                comp = <ActionValleyTrust
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />;
                break;
            case "PREDATOR":
            case "FOURMIS":
            case "PDG":
            case "REMOVE_FLOTTEUR":
            case "AZOTE_TITAN":
            case "ADD_RESOURCE_TO_ANOTHER_CARD":
                comp = <ActionAddResources
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />
                break;
            case "CONSULT_DECK":
                comp = <ActionConsultDeck
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />
                break;
            case "INFRASTRUCTURE_ELECTRIQUE":
                comp = <ActionInfrastructureElectrique
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />
                break;
            case "NAVETTE_TITAN":
                comp = <ActionNavetteTitan
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />;
                break;
            case "BACTERIES_SULFO_OXYDANTES":
                comp = <ActionBacteriesSulfoOxydantes
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />;
                break;
            case "ISOLATION":
                comp = <ActionIsolation
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />
                break;
            case "MAIN_DOEUVRE_ROBOTIQUE":
                comp = <ActionMainDoeuvreRobotique
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />;
                break;
            case "SELECT_FROM_DECK":
                comp = <ActionSelectFromDeck
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />;
                break;
            case "REVENDICATION_TERRAIN":
                comp = <ActionRevendicationTerrain
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />
                break;
            case "REDUCE_ANY":
                comp = <ActionRemoveAny
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />
                break;
            case "CHOICE":
            case  "CHOICE_VIRON":
                comp = <ActionChoice
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />
                break;
            case "DISCARD":
                comp = <ActionDiscardCard
                    actions={state.state.actions}
                    userId={user._id}
                    socket={socket}
                />;
                break;
            case "INTRO":
                return <div style={{
                    width: "100%",
                    height: "100%",
                    minHeight: '100vh',
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontSize: "20px"
                }}>
                    <StateIntro
                        state={state.state}
                        userId={user._id}
                        gameId={gameId}
                        username={user.username}
                        socket={socket}
                    />
                </div>;
                break;
        }
    }
    return <div style={{width: "100%", height: "100%", marginTop: "-1px", minHeight: "100vh"}}>
        <div className="board-game-home-top-bar" style={{zIndex: 99}}>
            <div
                className={"board-game-home-top-button board-game-home-top-button-" + (state.play ? "active" : "inactive")}
                onClick={() => setState(state => {
                    if (state.state.actions[user._id] && state.state.actions[user._id].length) {
                        return {...state, play: true};
                    }
                    return state;
                })}>
                {state.state.actions[user._id] && state.state.actions[user._id].length ?
                    "JOUER" :
                    "EN ATTENTE"
                }
            </div>
            <div
                className={"board-game-home-top-button board-game-home-top-button-" + (!state.play ? "active" : "inactive")}
                onClick={() => setState(state => ({...state, play: false}))}>
                CONSULTER
            </div>
        </div>
        {
            state.play ?
                <Box className="board-game" sx={{width: {xs: "80%", md: "50%"}}}>
                    {
                        comp || JSON.stringify(state.state)
                    }
                </Box>
                : <ConsultScreen
                    state={state.state}
                    userId={user._id}
                />
        }
    </div>

}

export default TerraformingHome;
